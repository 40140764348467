import $ from 'jquery';
import Venobox from 'venobox/venobox/venobox';

const selectorVenobox = '.venobox';

// @link http://veno.es/venobox/
function initVenobox() {
  console.log('venobox init');
  $(selectorVenobox).venobox({
    overlayColor: 'rgba(0, 132, 61, .95)',
    titleattr: 'data-title',
    titlePosition: 'bottom',
  });
}

function init() {
  if (document.querySelector(selectorVenobox) !== null) {
    initVenobox();
  }
}

export default {
  init,
};
